import React from "react";
import TrafficChangeChart from "./components/TrafficChangeChart";
import AttackLayer3 from "./components/AttackLayer3";
import PopularDomains from "./components/PopularDomains";

const App = () => {
  return (
    <>
      <TrafficChangeChart />
      <PopularDomains />
      <AttackLayer3 />
    </>
  );
};

export default App;
