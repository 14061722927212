import React, { useEffect } from "react";
import axios from "axios";
import styled from "@emotion/styled";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import config from "../util/config";

const Title = styled.div`
  font-size: 0.9rem;
  text-align: center;
  color: #484848;
  text-weight: bold;
`;

const PopularDomains = () => {
  const [domains, setDomains] = React.useState([]);
  useEffect(() => {
    axios.get(`${config.apiURL}/popular-domains`).then((res) => {
      console.log(res.data);
      const response = res.data;
      setDomains(response.rankingEntries);
    });
  }, []);

  return (
    <>
      <Title>Domain Rank</Title>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>rank</TableCell>
              <TableCell align="right">rankChange</TableCell>
              <TableCell align="right">domain</TableCell>
              <TableCell align="right">category</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {domains.map((row) => {
              let changeFlag;
              if (row.rankChange > 0) {
                changeFlag = "🔺";
              } else if (row.rankChange < 0) {
                changeFlag = "🔻";
              } else {
                changeFlag = "";
              }
              return (
                <TableRow
                  key={row.name}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.rank}
                  </TableCell>
                  <TableCell align="right">
                    {changeFlag} {row.rankChange}
                  </TableCell>
                  <TableCell align="right">{row.domain}</TableCell>
                  <TableCell align="right">{row.category}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default PopularDomains;
