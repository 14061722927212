const mergeTwoArrays = (array1, array2) => {
  let mergedArr = [];
  for (let i = 0; i < array1.length; i++) {
    if (mergedArr.indexOf(array1[i]) == -1) mergedArr.push(array1[i]);
  }
  for (let i = 0; i < array2.length; i++) {
    if (mergedArr.indexOf(array2[i]) == -1) mergedArr.push(array2[i]);
  }

  return mergedArr;
};

export { mergeTwoArrays };
