import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { mergeTwoArrays } from "../util/helpers";
import config from "../util/config";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Traffic Change Chart",
    },
  },
};

const TrafficChangeChart = () => {
  const [labels, setLabels] = useState([]);
  const [trafficChange, setTrafficChange] = useState({});
  const data = {
    labels,
    datasets: [
      {
        label: "total",
        data: trafficChange?.total?.timestamps.map(
          (data, index) => trafficChange?.total?.values[index]
        ),
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        label: "http",
        data: trafficChange?.http?.timestamps.map(
          (data, index) => trafficChange?.http?.values[index]
        ),
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };

  useEffect(() => {
    axios.get(`${config.apiURL}/traffic-change`).then((res) => {
      const response = res.data;
      const resLabels = mergeTwoArrays(
        response.data.total.timestamps,
        response.data.http.timestamps
      );
      setTrafficChange(response.data);
      setLabels(resLabels);
    });
  }, []);

  return <Line options={options} data={data} />;
};

export default TrafficChangeChart;
